<template>
  <div>
    <div class="chat-container is-flex-grow-1 px-3 py-5">
      <!-- MESSAGES -->
      <div v-for="(message, index) in messages" :key="index" class="is-clearfix block fade-up" ref="messageElements">
        <!-- USER message -->
        <div v-if="isUserMessage(message.type)" class="C1 is-flex is-flex-direction-row-reverse chat-message is-align-items-end">
          <b-notification :closable="false" class="p-3">
            <p>{{ message.content }}</p>
            <div class="mt-2 is-size-7 has-text-grey has-text-right">
              {{ $d(new Date(message.time), 'long') }}
            </div>
          </b-notification>
        </div>
        <!-- BOT message-->
        <div v-if="isBotMessage(message.type)" class="C2 is-flex chat-message is-align-items-end">
          <b-notification :closable="false" class="p-3 has-text-primary" type="is-primary is-light">
            <div>
              <b><i class="fas fa-sparkles"></i> {{ message.author }}</b>
              <p v-if="existMessages()" class="content">
                <vue-markdown :source="message.content" />
              </p>
              <p class="ml-1"><b-icon v-if="!message.content" icon="ellipsis fa-fade fa-2x has-text-left" /></p>
            </div>
            <div v-if="message.content" class="mt-2 is-size-7 has-text-primary has-text-right" ref="messageDate">
              {{ $d(new Date(message.time), 'long') }}
            </div>
          </b-notification>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator';
import { Messages } from '@/models/models';
import VueMarkdown from 'vue-markdown-render';

@Component({ components: { VueMarkdown } })
export default class ChatBody extends Vue {
  @Prop({ default: [] }) public readonly messages!: Messages[];
  @Ref('messageElements')
  public messageElements!: HTMLElement[];

  public isUserMessage(messageType: string): boolean {
    return messageType === 'user';
  }

  public isBotMessage(messageType: string): boolean {
    return messageType === 'assistant';
  }

  public existMessages(): boolean {
    return this.messages.length > 0;
  }

  @Watch('messages', { immediate: true, deep: true })
  onMessagesChange() {
    this.$nextTick(() => {
      if (this.messageElements && this.messageElements.length > 0) {
        const lastMessageElement = this.messageElements[this.messageElements.length - 1];
        if (lastMessageElement) {
          lastMessageElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }
}
</script>

<style lang="scss">
@import '../../assets/main.scss';
.chat {
  height: 100vh;

  .chat-container {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: lighten(#c4c2c2, 20%);
      border-radius: 10px;

      &:hover {
        background: lighten(#c4c2c2, 10%);
      }
    }

    .chat-message .notification {
      max-width: 85%;
    }
  }

  .fade-up {
    animation: fadeUp 1s ease 0s 1 normal forwards;
  }

  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
